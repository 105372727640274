import React from "react"
import Layout from "../../Components/pageComponents/contentContainer"

import { useStaticQuery, graphql } from "gatsby"
import SolutionInfoSection from "../../Components/pageComponents/solutionsInfoSection"
// import Img from "gatsby-image"


// import style from "./styles.module.css"

export default function DisasterRecovery() {

  const Image = useStaticQuery(graphql`
    query {
      banner: file(relativePath: {eq: "solutions/Disaster Recovery Plan/Disaster Recovery Plan.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pub: file(relativePath: {eq: "solutions/Disaster Recovery Plan/Disaster Recovery Plan Stock.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }`
  )
  const description = "Disaster Recovery Plan protects Business IT Infrastructure and will prepare the enterprise against total havoc in the instances of both a minor and a major disaster. This may include mirroring, replication, and other Data Management to avoid total loss of important business data and information. Fastlink will equip enterprises to avoid off-setting the business operations at a time that any disaster may occur. ";

  return (
    <Layout image={Image.banner.childImageSharp.fluid}
      title="Disaster Recovery">

      <SolutionInfoSection heading="Solutions We Offer" title="Disaster Recovery" description={description}
        img={Image.pub.childImageSharp.fluid} />

    </Layout>

  )
}